@import "src/themes/mojito/styles/index.scss";
.card-items-container {
  @include sm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }

  .card-list-swiper {
    margin: 2rem 0;
    padding-bottom: 2.5rem;

    .swiper-slide {
      max-width: 20rem;
      display: flex;
      justify-content: center;
    }

    svg {
      padding-top: 5px;
      width: 3rem !important;
      height: 3rem !important;
    }
  }

  .card-list-swiper--scrollbar-horizontal {
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
    text-align: center !important;
    height: 6px !important;
    background-color: getvar($colors, "primary", "100");
  }

  .card-list-swiper--scrollbar-drag {
    border-radius: 10px;
    background-color: getvar($colors, "primary", "500");
    height: 100%;
  }

  .card-item {
    max-width: 18.75rem;
    padding: 1.5rem;
    width: 200px;
    min-height: 235px;

    @include sm {
      max-width: space(20);
      min-height: space(10.688);
      width: space(18);
      padding: space(1);

      .u-br-desktop {
        display: inline;
      }
    }

    @include lg {
      height: 240px;
      max-width: 25rem;
      width: auto;
      padding: 1.5rem;

      .u-br-desktop {
        display: block;
      }
    }

    &:nth-of-type(even) {
      transform: translateY(1.5rem);
    }

    p {
      font-size: inherit;
      line-height: 1.5rem;
    }

    &--header {
      align-items: center;
      @include sm {
        align-items: left;
        gap: 7px;
      }

      p {
        font-weight: 700;
        text-align: left;
        margin-left: space(1.5);
        margin-bottom: 0;
        font-size: space(1.125);

        @include sm {
          margin-left: space(0);
          font-size: space(0.875);
          line-height: space(1) !important;
        }

        @include lg {
          margin-left: space(1.5);
          font-size: space(1.125);
        }
      }

      svg {
        @include sm {
          width: space(3);
          height: space(3);
        }

        @include lg {
          width: space(5.5);
          height: space(5.5);
        }
      }
    }

    &--body {
      margin-top: space(1);

      @include sm {
        margin-top: space(0.25);
      }

      p {
        text-align: left;
        line-height: space(1.5);

        @include sm {
          margin-left: space(0);
          font-size: space(0.875);
          line-height: space(1.25);
          margin-bottom: 0;
        }

        @include lg {
          font-size: space(1);
          line-height: space(1.65);
        }
      }
    }

    &.hasComponent {
      display: flex;
      flex: row;
      align-items: center;

      .card-item--body {
        @include sm {
          width: 98%;
        }
      }
    }
  }
}
