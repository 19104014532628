@import "src/themes/mojito/styles/index.scss";
.tabs > input[type="radio"] {
  position: absolute;
  left: -200vw;
}

.tabs .tab-panel {
  display: none;
}

.tabs > .tabs-input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabs > .tabs-input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
.tabs > .tabs-input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
.tabs > .tabs-input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
.tabs > .tabs-input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
.tabs > .tabs-input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6),
.tabs > .tabs-input:nth-child(13):checked ~ .tab-panels > .tab-panel:nth-child(7) {
  display: block;
}

.tabs > .tabs-label {
  position: relative;
  display: inline-block;
  padding: 15px 6px;
  cursor: pointer;
  font-weight: 600;
  width: 173px;
  margin-right: 6px;
  margin-top: 8px;
  @include sm {
    margin-right: 12px;
    margin-top: 0;
  }

  @include md {
    margin-right: 24px;
    width: 180px;
  }
}

.tabs > .tabs-label.tab-label--rounded,
.tabs > .tabs-label.tab-label--rounded:hover,
.tabs > .tabs-input:focus + .tabs-label.tab-label--rounded,
.tabs > .tabs-input:checked + .tabs-label.tab-label--rounded {
  @extend .u-rounded-button;
}

.tabs > .tabs-label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 22px;
  height: 4px;
}

.tabs-input:focus-visible + .tabs-label {
  border-radius: 3px;
}

.tabs > .tabs-label:hover,
.tabs > .tabs-input:focus + .tabs-label,
.tabs > .tabs-input:checked + .tabs-label {
  border-radius: 0.25rem;
  background-color: getvar($colors, "primary", "400") !important;
  color: #fff;
  outline: none;
  -webkit-text-decoration: underline 0.1em transparent;
  text-decoration: underline 0.1em transparent;
  text-decoration-color: transparent !important;
  -webkit-text-decoration-color: transparent !important;
}

.tabs > .tabs-input:checked + .tabs-label {
  margin-bottom: -1px;
}

.tab-panel {
  padding: 30px 0;
}

.tabs {
  max-width: 65em;
  text-align: center;
}

.tabs-label {
  span.subLabel {
    color: getvar($colors, "neutral", "400") !important;
  }
}
.tabs > .tabs-label:hover,
.tabs > .tabs-input:focus + .tabs-label,
.tabs > .tabs-input:checked + .tabs-label {
  span.subLabel {
    color: white !important;
  }
}
