@import "src/themes/mojito/styles/index.scss";
.card-box {
  display: flex;
  align-items: center;
  gap: space(1);
  flex-direction: column;
  height: 380px;
  padding: space(1.5) space(1) !important;

  @include sm {
    gap: space(1.5);
    flex-direction: row;
    height: 250px;
    padding: space(1.5) !important;
  }

  &--shadow {
    box-shadow: 5px 0px 8px 0px #a6a6a640;
  }

  .card-box-image-section {
    width: 100%;

    @include sm {
      width: 216px;
      height: 216px;
    }

    .card-box-image {
      border-radius: space(0.5);
      width: 100%;
      height: 136px;
      object-fit: cover;
      object-position: 100% 20%;
      overflow: hidden;

      @include sm {
        width: 216px;
        height: 216px;
        overflow: auto;
      }
    }
  }

  .card-box-section {
    &--left {
      text-align: left;
    }
    &--center {
      text-align: center;
    }
  }
  .card-box-title {
    color: getvar($colors, "neutral", "900");
  }
  .card-box-content {
    color: getvar($colors, "neutral", "900");
  }
}
