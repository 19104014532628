@import "src/themes/mojito/styles/index.scss";
#frames-select {
  display: block;

  @include sm {
    display: none;
  }
}

.FramesSelect {
  text-align: left;
  @extend .u-spacing-5;

  &__wrapper {
    position: relative;

    svg {
      pointer-events: none;
      height: space(1.25);
      width: space(1.25);
      color: getvar($colors, "neutral", "700");
      position: absolute;
      right: 12px;
      top: 11px;
    }
  }

  &__prefix {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: space(0.875);
    padding-left: space(0.5);
    color: getvar($colors, "neutral", "300");
  }

  select {
    appearance: none;
    cursor: pointer;
    padding: space(0.5) space(2) space(0.5) space(0.75);
    background-color: getvar($colors, "neutral", "50");
    border-radius: 0.25em;
    border: 1px solid getvar($colors, "neutral", "300");
    font-size: 14px;
    outline: 0;
    height: space(2.5);
    width: 100%;
    background-color: white !important;

    option {
      color: getvar($colors, "neutral", "900");
      padding: space(0.75) space(2) space(0.75) space(0.75);
    }

    &:hover {
      border: 1px solid getvar($colors, "neutral", "500");
    }

    &:focus {
      outline: none;
      border: 1px solid getvar($colors, "primary", "500");
    }

    &::-ms-expand {
      display: none;
    }

    &:invalid {
      color: getvar($colors, "neutral", "500");
    }

    option[value=""] {
      display: none;
    }
  }

  &__error-message {
    display: flex;
    margin-top: space(0.25);
    margin-bottom: space(0.5);
    align-items: center;

    svg {
      color: getvar($colors, "error", "600");
      margin-right: space(0.25);
    }
  }

  &--error {
    .FramesSelect__error-message {
      color: getvar($colors, "error", "600");
    }

    select {
      border: 1px solid getvar($colors, "error", "600");
    }
  }

  &--success {
    select {
      border: 1px solid getvar($colors, "neutral", "600");
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    select {
      background-color: getvar($colors, "neutral", "100");
      border: 1px solid getvar($colors, "neutral", "500");
    }
  }

  &__helper {
    color: getvar($colors, "neutral", "400");
    display: inline-block;
    font-size: space(0.75);
    line-height: space();
    margin-top: space(0.5);
  }
}

.FramesSelect__element--placeholder {
  font-size: 1rem;
}

.select-content {
  width: 85%;
  margin: 0 auto;
  max-width: 85%;

  .Picker__item {
    width: 96px !important;
    height: 104px !important;
  }

  .div-toggle .tab-picker-frame {
    justify-content: center;
    @include sm {
      justify-content: flex-start !important;
    }
  }
}

