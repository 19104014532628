@import "src/themes/mojito/styles/index.scss";
.collapsible {
  &-accordion-css {
    margin: 10px;
    overflow: hidden;
  }
}

.css-collapsible {
  &-item {
    .accordion-input {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      font-weight: bold;
      cursor: pointer;

      &::after {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
        width: 0.8em;
        height: 0.8em;
        text-align: center;
        transition: all 0.2s;
      }
    }
    &-content {
      max-height: 0;
      padding: 0 1em;
      background: white;
      transition: all 0.2s;
    }

    .accordion-input:checked {
      + .css-collapsible-item-label {
        &::after {
          transform: rotate(-0.5turn);
        }
      }

      ~ .css-collapsible-item-content {
        max-height: 100vh;
        padding: space(1.25) space(1.25);

        @include md {
          padding: space(1.5) space(1.75);
        }
      }
    }
  }
}

/** styles **/
.accordion-css {
  @extend .u-box--small;
  @extend .u-spacing-4;
  @extend .u-shadow-small;
  padding: 0;
  text-align: left;

  &__title {
    color: getvar($colors, "neutral", "900");
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: space(1.25) space(1.25);
    cursor: pointer;
    font-size: space(1);

    @include sm {
      max-width: 100%;
      width: 95% !important;
    }

    @include md {
      padding: space(1.5) space(1.75);
    }
  }

  &__content {
    color: getvar($colors, "neutral", "900");
  }
}
